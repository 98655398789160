<template src="./login.html" />

<script>
import mixins from "@/mixins/mixins";

import { ToolIcon } from "vue-feather-icons";
import { useStore } from "@/store/store.js";
import { computed } from "vue";
import { checkCampaignElegibility } from "@/helpers/campaigns/elegibility";

export default {
  mixins: [mixins],

  components: {
    ToolIcon,
  },

  data() {
    return {
      showWebButtons: true,
      email: "",
      cpf: "",
      isHorasDaVida: false,
      seoMsg: "Bem-vindo ao Dasa +Saúde",
      password: "",
      checkbox_remember_me: true,
      bearer_code: "1",
      senhaInvalida: false,
      usuarioInexistente: false,
      usuarioFirstAccess: false,
      usuarioTaxPaidFalse: false,
      invalidCpf: false,
      emailVazio: false,
      passwordVazio: false,
      passwordType: "password",
      medRecId: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  setup() {
    const store = useStore();

    return {
      env: computed(() => store.getters.enviroment),
    };
  },

  created: function () {
    if (this.$route.name === "login-horas") {
      this.isHorasDaVida = true;
      this.seoMsg = "Bem-vindo ao Horas da Vida";
    }

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  mounted: async function () {
    const vm = this;
    vm.$vs.loading.close();
    if (!vm.testNullUndefinedEmpty(vm.$router.history.current.params.token)) {
      vm.$session.start();
      vm.$session.set("bearer", vm.$router.history.current.params.token);
      vm.bearer_code = vm.$router.history.current.params.token;
      await vm.getMe();
      vm.$router.push("/plan-upgrade");
    } else if (vm.$cookies.get("user") !== null) {
      vm.email = vm.$cookies.get("user").userName;
      vm.password = vm.$cookies.get("user").password;
    }
  },

  destroyed: function () {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.showWebTable = window.innerWidth > 1025;
    },
    renew() {
      var cpf = this.cpf.replace(".", "").replace(".", "").replace("-", "");

      this.axios
        .post(`${this.$store.state.filooServer}users/forgot-password`, {
          cpf: cpf,
          type: "PATIENT",
        })
        .then(() => {
          this.$bvModal.hide("modal-forgot-password");
          this.$swal(
            "Alteração de senha",
            "Dados para a alteração da senha enviados para os canal de comunicação cadastrado.",
            "success"
          );
        })
        .catch((error) => {
          this.$bvModal.hide("modal-forgot-password");
          if (!error.response) {
            this.$swal(
              "Alteração de senha",
              `Não foi possível enviar os dados para a alteração da senha.
                Verifique se o CPF foi digitado corretamente.`,
              "warning"
            );
          } else if (error.response.status === 412) {
            this.$bvModal.show("modal-register-incompleted");
          }
        });
    },
    checkCPF() {
      this.invalidCpf = !this.validateCPF(this.cpf);

      if (!this.invalidCpf) {
        this.renew();
      }
    },
    async login() {
      const redirect = this.$session.get("redirect") || false;
      const campaign = this.$session.get("campaign") || false;

      this.$gtm.trackEvent({
        // click_btn_pc_login_acessar
        event: "login_access_btn_click",
        category: "Login",
        action: "login_access_btn_click",
        label:
          "Na página de Login do Portal Cliente - Clicar no botão “Acessar“",
      });

      this.emailVazio = !this.email;
      this.passwordVazio = !this.password;

      if (!this.emailVazio && !this.passwordVazio) {
        this.axios
          .post(`${this.$store.state.filooServer}patient/login`, {
            username: this.email,
            password: this.password,
          })
          .then(async ({ status, headers }) => {
            if (status === 200) {
              this.$session.destroy();
              this.$session.start();
              this.$cookies.remove("user");
              this.$session.set("bearer", headers.authorization);

              this.bearer_code = headers.authorization;

              if (this.checkbox_remember_me) {
                this.$cookies.set("user", {
                  userName: this.email,
                  password: this.password,
                });
              }

              await this.getMe();

              if (campaign) {
                const planIds = campaign.plans[this.env];
                // const serviceIds = campaign.services[this.env]

                const elegibility = await checkCampaignElegibility(
                  this.$session.get("patientId"),
                  { plans: planIds } //, services: serviceIds }
                );

                if (!elegibility || !elegibility.valid) {
                  const params = {};

                  if (!elegibility.valid) {
                    params.campaignErrorMessage = elegibility.message;
                  }

                  return this.$router.push({ name: "plan-upgrade", params });
                }

                return this.$router.push(redirect);
              } else {
                this.$router.push(redirect ? redirect : "/plan-upgrade");
              }
            }
          })
          .catch((error) => {
            // this.$bvModal.show("modal-under-maintenance");
            const msg =
              error.data && error.data.message ? error.data.message : null;

            if (msg === "First access" || msg === "Usuário não cadastrado") {
              this.usuarioFirstAccess = true;
            }
            if (msg === "Não identificamos o pagamento da sua fatura") {
              this.usuarioTaxPaidFalse = true;
            }
            if (
              msg === "exception.patient.notfound" ||
              msg === "No message available" ||
              msg === "Nenhum paciente ativo encontrado" ||
              msg === "Usuário não cadastrado"
            ) {
              this.usuarioInexistente = true;
            }
            if (msg === "Usuário ou senha inválido") {
              this.senhaInvalida = true;
            }
            if (error.status === 401) {
              this.$vs.notify({
                title: "Usuário não autorizado!!",
                text: "Login ou senha estão incorretos",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
              this.senhaInvalida = true;
            }
          });
      }
    },
    register() {
      this.$bvModal.hide("modal-register-incompleted");
      this.$router.push("/first-access");
    },
    passwordVisibility() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss" src="./login.scss" />
